/*fonts*/
$roboto: "Roboto", sans-serif;

// colours
$white: #fff;
$black: #070707;
$primary: #3c97d8;
$error: #F6133D;
$danger: #F6133D;
$secondary: #2CCB8D;
$main: #E77228;
$success: #2CCB8D;
$default: #E8E8E8;

