@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  background-color: #f8f8f8;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.pac-container{
  z-index: 9999;

}

form {
  label {
    color: #858688;
    font-size: 12px;
  }
  .form-control[type="text"],
  .form-control[type="password"],
  .form-select,
  textarea.form-control,
  .form-control[type="number"],
  .MuiSelect-select {
    border: none !important;
    border-radius: 0;
    outline: none;
    box-shadow: none !important;
    background: #f7f7f7;
    border-bottom: 1px solid #e8e8e8;
    font-size: 1rem;
    line-height: 1.5;
    &[disabled] {
      color: rgba(0, 0, 0, 0.38);
    }
  }
  .form-control[type="text"],
  .form-control[type="number"],
  .form-select
  // .MuiSelect-select
  {
    padding: 16px 12px 12px 12px;
    border-bottom: 2px solid #e8e8e8;
  }
  .form-control[type="password"] {
    padding: 16px 50px 12px 12px;
  }
  .form-label {
    margin-bottom: 0;
  }
}
.grecaptcha-badge {
  display: none;
}

.react-multi-carousel-dot-list {
  position: relative !important;
  margin: 20px 0 12px;
}

.react-multi-carousel-dot button {
  border: 1px solid #e77228 !important;
  opacity: 0.5;
  border-radius: 6px;
  background-color: #e77228;

  &:active {
    background-color: #e77228;
  }
}

.react-multi-carousel-dot {
  padding-top: 10px;

  button {
    border: 1px solid #e77228 !important;
    opacity: 0.5 !important;
    background-color: #e77228;
    height: 10px !important;
    width: 10px !important;
    padding: 0px !important;
  }

  &--active button {
    background: #e77228 !important;
    opacity: 1 !important;
    width: 24px !important;
    height: 10px !important;
    border-radius: 6px !important;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.flex-row {
  display: flex;

  &.flex-center-y {
    align-items: center;
  }
}

// Common

.background-primary {
  background: #e77228 !important;
}

// Text

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 15px !important;
}

.text-lg {
  font-size: 17px !important;
  line-height: 18px !important;
}

.text-xl {
  font-size: 19px !important;
  list-style: 32px !important;
}

.text-highlight {
  color: #e77228 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-required {
  color: #f6133d;
  margin-left: 6px;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700;
}
.text-error {
  color: #f6133d;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  margin-top: 3px;
}
.text-underline {
  text-decoration: underline;
}

.text-ellipsis-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Toast custom

.toast-top-center {
  top: 100px;
}

#toast-container {
  & > div {
    width: 800px !important;
  }
  & .toast {
    border-radius: 8px;
    opacity: 1;
    box-shadow: none;

    & .toast-message {
      color: #151b22;
    }

    & .toast-close-button {
      color: initial;
      opacity: 0.5;
    }

    &.toast-success {
      border: 1px solid #2ccb8d;
      background-color: #f8fffc;
      background-image: url("../public/static/images/icons/ic_noti_successfully.png") !important;
    }

    &.toast-info {
      border: 1px solid #04b0ed;
      background-color: #f4fcff;
      background-image: url("../public/static/images/icons/ic_noti_informative.png") !important;
    }

    &.toast-error {
      border: 1px solid #f6133d;
      background-color: #ffeef1;
      background-image: url("../public/static/images/icons/ic_noti_error.png") !important;
    }
  }
}

.container {
  max-width: 1289px !important;
  // padding: 0 !important;
}

.row {
  margin: 0 !important;
}

.hide-on-pc {
  display: none !important;
}

// Menu item

.MuiMenu-list {
  & li {
    font-weight: 500;
    &:hover {
      color: #e77228;
      background-color: rgba(238, 156, 104, 0.2) !important;
    }
  }

  .Mui-selected {
    color: #e77228;
    background-color: #ffffff !important;
  }
}

.MuiAutocomplete-popper {
  & > li {
    font-weight: 500;
    &:hover {
      color: #e77228;
      background-color: rgba(238, 156, 104, 0.2) !important;
    }
  }
  .Mui-selected {
    color: #e77228;
    background-color: #ffffff !important;
  }
  & .MuiAutocomplete-listbox {
    max-height: 210px !important;
    overflow: auto;
  }
}

.MuiFormLabel-asterisk {
  color: #f6133d;
  font-weight: 500;
  margin-left: 6px;
}

// Background color

.bg-white {
  background-color: #ffffff;
}

@media only screen and (max-width: 993px) {
  .hide-on-pc {
    display: block !important;
  }
  .hide-on-mobile {
    display: none !important;
  }
}

// Flex
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-x {
  display: flex;
  justify-content: center;
}

.flex-center-y {
  display: flex;
  align-items: center;
}

// State

.blurry-text {
  filter: blur(4px);
  user-select: none;
  & * {
    filter: blur(6px);
  }
}

.rti--container { 
  background-color: #f8f8f8 !important;
  border: none !important;
  border-radius: 10px !important;
  min-height: 45px !important;
 

}

.rti--tag{
  background-color: #69cdae !important;
  color: white !important;
  border-radius: 10px !important;
}
.rti--input{
  background-color: #f8f8f8 !important;
}
.rti--tag button{
  color:white !important;
}


.notification-info{
  background-color: black;
}


