@use 'sass:color';
@import "public/static/scss/variables";

.modal {
    z-index: 2002 !important;

    .modalContainer {
        max-width: none;
    }
    .modalContainerXSm {
        width: 400px;
    }
    .modalContainerSm {
        width: 600px;
    }
    .modalContainerMd {
        width: 700px;
    }
    .modalContainerlg {
        width: 960px;
    }
    .modalContainerXl {
        width: 1150px;
    }
    .modalContent {
        padding: 0 !important;
        // @include scrollbar();
        &::-webkit-scrollbar {
            width: 10px;
        }
    
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #f5f5f5;
            border: 1px solid #eee;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #668c9b;
        }
    }
    .modalConfirm,
    .modalSuccess {
        width: 700px;
    }
    .modalBody {
        padding: 48px;
    }

    .modalTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px;
        // text-transform: uppercase;
        font-weight: bold;
        // background-color: #f9f9f9;
        font-size: 22px;
        font-family: $roboto;

        &.modalTitleBorder {
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.07),
                0 2px 1px -1px rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        }

        .close {
            position: absolute;
            top: 8px;
            right: 8px;
            color: #cdcdcd;
        }
    }

    // in confirm dialogs to tell the user something
    .modalCommand {
        padding: 0;
        text-align: center;
        color: #444;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.7em;
        font-family: $roboto;
    }

    .modalDescription {
        margin-top: 40px;
        margin-bottom: 0;
    }
}